import React, { useMemo } from "react";

import { Filter, X } from "lucide-react";
import tw from "twin.macro";

import { FilterItem } from "@/app/core/datatable/filters/components/FilterItem";
import { styled } from "@/app/core/styles/stitches.config";
import { Flex } from "@/app/core/ui/components/Flex";
import { Text } from "@/app/core/ui/components/Text";
import type { ClientFacets, TableFiltersFacet } from "@obd/common";

const FilterButton = styled("button", {
  ...tw`flex items-center justify-center shadow border border-gray-200
  rounded-lg gap-0.75 px-1 py-0.75 hover:shadow-md transition-all`,
  variants: {
    accent: {
      true: tw`bg-accent text-white border-accent-light hover:bg-accent-light`,
    },
  },
});

interface FilterListProps {
  activeFilters: ClientFacets;
  filters: TableFiltersFacet[];
  onFilterAdd: (blockIndex?: number) => void;
  onFilterRemove: (blockIndex: number, itemIndex?: number) => void;
  clearFilters: () => void;
  simple: boolean;
}

export const FilterList: React.FC<FilterListProps> = ({
  activeFilters,
  filters,
  onFilterAdd,
  onFilterRemove,
  clearFilters,
  simple,
}) => {
  const filterItems = useMemo(() => filters.map((f) => f.items).flat(), [filters]);

  return (
    <Flex tw="flex-col m-3 gap-1">
      <Flex tw="items-center justify-between mb-2">
        <Text weight="bold" size="lg">
          Filtros activos ({activeFilters.map((f) => f).flat().length})
        </Text>
        <Text
          as="button"
          size="md"
          weight="bold"
          color="accent-faded"
          onClick={clearFilters}
          tw="underline"
        >
          Borrar filtros
        </Text>
      </Flex>
      {activeFilters.map((block, i) => (
        <Flex key={"filter-block-" + i}>
          <Text
            tw="w-8 py-1.25"
            weight="medium"
            color={i === 0 ? "gray-900" : "gray-700"}
          >
            {i === 0 ? "Donde" : "O"}
          </Text>

          <Flex
            tw="rounded-lg gap-0.5 flex-wrap p-0.5 w-full"
            css={i !== 0 ? tw`border border-gray-200` : {}}
          >
            {block.map((item, i2) => (
              <React.Fragment key={item.id + i2}>
                <FilterItem
                  item={item}
                  filter={filterItems.find((fi) => fi.id === item.id)}
                  onRemove={() => onFilterRemove(i, i2)}
                />

                {i2 < block.length - 1 && (
                  <Text
                    tw="flex items-center bg-white border border-gray-200 px-1 py-0.75 rounded-lg"
                    weight="bold"
                    size="sm"
                    color="gray-700"
                  >
                    Y
                  </Text>
                )}
              </React.Fragment>
            ))}

            <FilterButton onClick={() => onFilterAdd(i)}>
              <Filter size="12" />
              {block.length === 0 && (
                <Text size="sm" weight="medium">
                  Añadir filtro
                </Text>
              )}
            </FilterButton>
          </Flex>

          <Flex
            as="button"
            tw="items-center justify-end basis-[6%] text-gray-700 hover:(text-red-500)"
            disabled={i === 0}
            onClick={() => onFilterRemove(i)}
          >
            {i !== 0 && <X size="15" />}
          </Flex>
        </Flex>
      ))}

      {!simple && (
        <FilterButton
          tw="ml-[7rem] self-start text-sm font-bold py-0.25"
          accent
          onClick={() => onFilterAdd()}
        >
          Añadir grupo de filtros
        </FilterButton>
      )}
    </Flex>
  );
};
