import React, { useMemo } from "react";

import { X } from "lucide-react";
import tw from "twin.macro";

import { Box } from "@/app/core/ui/components/Box";
import { Flex } from "@/app/core/ui/components/Flex";
import { Text } from "@/app/core/ui/components/Text";
import { date } from "@/app/core/utils";
import type {
  ClientFacet,
  TableFiltersFacetItem,
  TableFiltersFacetOperator,
} from "@obd/common";

const getOpDisplayName = (filter: TableFiltersFacetOperator) => {
  switch (filter.op) {
    case "eq":
      if (filter.type === "boolean") {
        return "Es verdadero";
      }
      return "Coincide exactamente con";
    case "notEq":
      if (filter.type === "boolean") {
        return "Es falso";
      }
      return "No coincide exactamente con";
    case "in":
      if (filter.type === "date") {
        return "Se encuentra entre las fechas";
      }

      if (filter.type !== "select") {
        return "Contiene exactamente";
      }

      return "Es cualquiera de";
    case "notIn":
      if (filter.type === "date") {
        return "No se encuentra entre las fechas";
      }

      if (filter.type !== "select") {
        return "No contiene";
      }

      return "No es cualquiera de";
    case "empty":
      return "Es desconocido";
    case "notEmpty":
      return "Es conocido";
    case "gte":
      return "Es más grande o igual que";
    case "lte":
      return "Es más pequeño o igual que";
    default:
      return "??";
  }
};

interface FilterItemProps extends React.ComponentProps<typeof Flex> {
  item: ClientFacet;
  filter?: TableFiltersFacetItem;
  onRemove?: () => void;
  size?: "base" | "sm";
}

export const FilterItem: React.FC<FilterItemProps> = ({
  item: _item,
  filter,
  onRemove,
  size = "base",
  ...props
}) => {
  const operator = filter?.operators.find(({ op }) => op === _item.op);

  const value = useMemo(() => {
    const item = { ..._item };

    if (
      !filter ||
      !operator ||
      item.value === undefined ||
      (Array.isArray(item.value) && item.value.length === 0)
    ) {
      return null;
    }

    if (Array.isArray(item.value)) {
      const itemValue = [...item.value];

      if ((operator.op === "in" || operator.op === "notIn") && operator.type === "date") {
        if (itemValue[0]) {
          itemValue[0] = date.format(new Date(itemValue[0]), "dd/MM/yyyy");
        }

        if (itemValue[1]) {
          itemValue[1] = date.format(new Date(itemValue[1]), "dd/MM/yyyy");
        }
      }

      if (itemValue.length === 1) {
        return itemValue[0];
      }

      const first = itemValue.slice(0, itemValue.length - 1).join(", ");
      const last = itemValue[itemValue.length - 1];

      return (
        <>
          {first}{" "}
          <Text as="span" weight="normal" color="gray-800">
            o
          </Text>{" "}
          {last}
        </>
      );
    }

    if (typeof item.value === "boolean") {
      return item.value ? "verdadero" : "falso";
    }

    return item.value;
  }, [_item, filter, operator]);

  if (!value && !["empty", "notEmpty"].includes(operator?.op ?? "")) {
    return null;
  }

  return (
    <Flex
      tw="self-start items-center gap-1 rounded-lg bg-gray-150 border border-gray-200"
      css={size === "base" ? tw`text-md px-1 py-0.75` : tw`text-sm px-0.75 py-0.5`}
      {...props}
    >
      <Box tw="text-gray-800 font-medium">
        <Text as="span">
          {filter!.name} {getOpDisplayName(operator!).toLowerCase()}
        </Text>{" "}
        <Text as="span" weight="bolder" color="gray-900">
          {operator && "type" in operator && operator.type === "boolean" ? "" : value}
        </Text>{" "}
      </Box>
      {onRemove && (
        <X size="12" tw="flex-shrink-0 text-gray-700 cursor-pointer" onClick={onRemove} />
      )}
    </Flex>
  );
};
