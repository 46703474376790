import React, { useEffect, useState } from "react";

import { ArrowRight, Calendar } from "lucide-react";

import { DatePickerTrigger } from "@/app/core/display/picker/date/components/DatePickerTrigger";
import { DatePicker } from "@/app/core/display/picker/date/DatePicker";
import { Text } from "@/app/core/ui/components/Text";
import { date } from "@/app/core/utils";

interface FilterDateProps {
  setValue: (v: Date[]) => void;
}

export const FilterDate: React.FC<FilterDateProps> = ({ setValue }) => {
  const [selected, setSelected] = useState<Date[]>();

  useEffect(() => {
    if (selected?.length) {
      const first = selected[0];
      let last: Date | undefined = undefined;
      if (selected.length > 1) {
        last = selected[selected.length - 1];
      }

      setValue([first, last].filter(Boolean) as Date[]);
    }
  }, [selected, setValue]);

  return (
    <DatePicker onSelect={setSelected} selected={selected}>
      <DatePickerTrigger tw="self-start flex items-center bg-white border border-gray-300 px-1.5 py-1 gap-1.5 rounded-lg w-2/3">
        <Calendar size="17" tw="text-gray-600" />
        {(!selected || selected?.length === 0) && (
          <Text size="md" weight="medium">
            Selecciona un rango de fechas
          </Text>
        )}

        {selected && selected.length > 0 && (
          <Text size="md" weight="medium">
            {date.format(selected[0], "dd/MM/yyyy")}
          </Text>
        )}

        {selected && selected.length > 1 && (
          <>
            <ArrowRight size="14" />
            <Text size="md" weight="medium">
              {date.format(selected[selected.length - 1], "dd/MM/yyyy")}
            </Text>
          </>
        )}
      </DatePickerTrigger>
    </DatePicker>
  );
};
