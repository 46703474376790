import tw from "twin.macro";

import { styled } from "@/app/core/styles/stitches.config";

export const PopoverItem = styled("button", {
  ...tw`w-full flex items-center px-1 py-0.75 gap-1.25 rounded-lg`,
  variants: {
    noHover: {
      true: tw``,
      false: tw`hover:bg-gray-100`,
    },
  },
  defaultVariants: {
    noHover: false,
  },
});
