import React from "react";

import { Popover } from "@headlessui/react";
import { AnimatePresence, motion } from "framer-motion";

import type { FCWithChildren } from "@/app/core/types/tsx";

interface PopoverPanelProps
  extends FCWithChildren,
    Omit<React.ComponentProps<typeof motion.div>, "children"> {
  open: boolean;
}

export const PopoverPanel: React.FC<PopoverPanelProps> = ({
  open,
  children,
  ...props
}) => {
  return (
    <AnimatePresence>
      {open && (
        <Popover.Panel
          as={motion.div}
          tw="absolute z-10 bg-white rounded-lg shadow-lg border border-gray-300
              p-0.5 mt-1 right-0 top-full"
          static
          layout
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          transition={{
            type: "spring",
            bounce: 0.4,
            duration: 0.4,
          }}
          {...props}
        >
          {children}
        </Popover.Panel>
      )}
    </AnimatePresence>
  );
};
