import React, { useCallback, useState } from "react";

import { FilterOperator } from "@/app/core/datatable/filters/components/FilterOperator";
import { Button } from "@/app/core/ui/components/Button";
import { Flex } from "@/app/core/ui/components/Flex";
import { Text } from "@/app/core/ui/components/Text";
import type { ClientFacet, TableFiltersFacetItem } from "@obd/common";

interface FilterTabProps {
  filter: TableFiltersFacetItem;
  setFilter: (filter: ClientFacet) => void;
}

export const FilterTab: React.FC<FilterTabProps> = ({ filter, setFilter }) => {
  const [activeOperator, setActiveOperator] = useState(0);
  const [currentFilter, setCurrentFilter] = useState<ClientFacet>();
  const handleCurrentFilter = useCallback(
    (value: Omit<ClientFacet, "id">) => setCurrentFilter({ ...value, id: filter.id }),
    [filter.id]
  );

  return (
    <Flex tw="flex-col px-3 py-2 gap-0.5">
      <Text weight="bold" size="lg" tw="mb-1.5">
        {filter.name}
      </Text>

      {filter.operators.map((operator, i) => (
        <FilterOperator
          operator={operator}
          key={operator.op + i}
          active={activeOperator === i}
          onActive={() => setActiveOperator(i)}
          setFilter={handleCurrentFilter}
        />
      ))}

      <Button
        size="md"
        theme="accent"
        tw="self-start mt-2"
        onClick={() => setFilter(currentFilter!)}
        disabled={!currentFilter}
      >
        Aplicar filtro
      </Button>
    </Flex>
  );
};
