import React, { useCallback, useMemo } from "react";

import { useDebouncedCallback } from "@react-hookz/web";
import tw from "twin.macro";

import { FilterDate } from "@/app/core/datatable/filters/components/FilterDate";
import { FilterSelect } from "@/app/core/datatable/filters/components/FilterSelect";
import { Input } from "@/app/core/forms/components/Input";
import type { FCWithChildren } from "@/app/core/types/tsx";
import { Absolute } from "@/app/core/ui/components/Absolute";
import { Box } from "@/app/core/ui/components/Box";
import { Flex } from "@/app/core/ui/components/Flex";
import { Text } from "@/app/core/ui/components/Text";
import type { ClientFacet, TableFiltersFacetOperator } from "@obd/common";

interface RadioCardProps extends FCWithChildren {
  active: boolean;
  label: string;
  onActive: () => void;
}

export const RadioCard: React.FC<RadioCardProps> = ({
  active,
  label,
  children,
  onActive,
}) => {
  return (
    <Flex
      tw="cursor-pointer flex-col rounded-lg px-1.5 py-1.5 gap-1"
      css={active ? tw`bg-gray-150` : tw`hover:bg-gray-100`}
      onClick={onActive}
    >
      <Flex tw="items-center gap-1">
        <Box
          tw="w-1.5 h-1.5 border border-gray-700 rounded-full relative"
          css={active ? tw`border-accent` : {}}
        >
          <Absolute align="center">
            <Box
              tw="w-[0.72rem] h-[0.72rem] rounded-full"
              css={active ? tw`bg-accent` : tw`bg-transparent hover:bg-gray-700`}
            />
          </Absolute>
        </Box>
        <Text weight="medium" css={active ? tw`text-gray-900` : tw`text-gray-700`}>
          {label}
        </Text>
      </Flex>

      {active && <Flex tw="pl-2.5 w-full">{children}</Flex>}
    </Flex>
  );
};

interface FilterOperatorProps {
  operator: TableFiltersFacetOperator;
  active: boolean;
  onActive: () => void;
  setFilter: (value: Omit<ClientFacet, "id">) => void;
}

export const FilterOperator: React.FC<FilterOperatorProps> = ({
  operator,
  active,
  onActive,
  setFilter,
}) => {
  const handleSetFilter = useCallback(
    (value: Pick<ClientFacet, "value">["value"]) => {
      setFilter({ op: operator.op, value });
    },
    [operator.op, setFilter]
  );

  const handleSetFilterDebounced = useDebouncedCallback(handleSetFilter, [], 500);

  const handleSetDateValue = useCallback(
    (dates: Date[]) => {
      handleSetFilter(dates.map((d) => d.getTime()));
    },
    [handleSetFilter]
  );

  const label = useMemo<string>(() => {
    if (operator.op === "eq") {
      if (operator.type === "boolean") {
        return "Es verdadero";
      }

      if (operator.type === "number") {
        return "Es igual a";
      }
    }

    if (operator.op === "notEq") {
      if (operator.type === "boolean") {
        return "Es falso";
      }
    }

    if (operator.op === "in") {
      if (operator.type === "date") {
        return "Está dentro del rango de fechas";
      }

      if (operator.type !== "select") {
        return "Contiene exactamente";
      }

      return "Es cualquiera de";
    }

    if (operator.op === "notIn") {
      if (operator.type === "date") {
        return "No está dentro del rango de fechas";
      }

      if (operator.type !== "select") {
        return "No contiene exactamente";
      }

      return "No es cualquiera de";
    }

    if (operator.op === "empty") {
      return "Es desconocido";
    }

    if (operator.op === "notEmpty") {
      return "Es conocido";
    }

    if (operator.op === "gte") {
      return "Es más grande o igual que";
    }

    if (operator.op === "lte") {
      return "Es más pequeño o igual que";
    }

    return "???";
  }, [operator]);

  return (
    <RadioCard
      label={label}
      active={active}
      onActive={() => {
        if (["empty", "notEmpty"].includes(operator.op)) {
          handleSetFilter(undefined);
        }

        if (
          (operator.op === "eq" || operator.op === "notEq") &&
          operator.type === "boolean"
        ) {
          handleSetFilter(operator.op === "notEq" ? "false" : "true");
        }

        onActive();
      }}
    >
      {["in", "notIn"].includes(operator.op) &&
        "type" in operator &&
        operator.type !== "date" && (
          <FilterSelect
            setValue={handleSetFilter}
            options={
              "values" in operator
                ? operator.values
                : operator.type === "boolean"
                ? ["Verdadero", "Falso"]
                : undefined
            }
          />
        )}

      {(operator.op === "gte" || operator.op === "lte" || operator.op === "eq") &&
        operator.type === "number" && (
          <Input
            type="number"
            name={`filter-${operator.op}-${operator.type}`}
            onChange={(ev) =>
              isNaN(Number(ev.target.value))
                ? {}
                : handleSetFilterDebounced(Number(ev.target.value))
            }
          />
        )}

      {"type" in operator && operator.type === "date" && (
        <FilterDate setValue={handleSetDateValue} />
      )}
    </RadioCard>
  );
};
