import React, { useEffect, useRef, useState } from "react";

import { Combobox } from "@headlessui/react";
import { useDebouncedEffect } from "@react-hookz/web";
import { ChevronsUpDown, X } from "lucide-react";

import { useApiQuery } from "@/app/core/http/hooks/useApiQuery";
import { Absolute } from "@/app/core/ui/components/Absolute";
import { Badge } from "@/app/core/ui/components/Badge";
import { Box } from "@/app/core/ui/components/Box";

interface FilterSelectProps {
  options?: string[] | string;
  setValue: (v: string[]) => void;
}

export const FilterSelect: React.FC<FilterSelectProps> = ({
  options: incomingOptions,
  setValue,
}) => {
  const [filterOptions, setFilterOptions] = useState(
    incomingOptions && Array.isArray(incomingOptions) ? incomingOptions : undefined
  );
  const [selected, setSelected] = useState<string[]>([]);
  const [query, setQuery] = useState("");
  const [serverQuery, setServerQuery] = useState("");

  useDebouncedEffect(
    () => {
      setServerQuery(query);
    },
    [query],
    500
  );

  const { data: serverData } = useApiQuery(
    [(typeof incomingOptions === "string" ? incomingOptions : "-") as any, serverQuery],
    {
      request: { query: { search: serverQuery } },
      staleTime: 1000 * 60 * 10,
      enabled: typeof incomingOptions === "string",
    } as any
  );

  useEffect(() => {
    if (serverData && Array.isArray(serverData)) {
      setFilterOptions(
        serverData.map((d) => {
          if (typeof d === "string") {
            return d;
          }

          if (typeof d === "object" && "name" in d) {
            return d.name;
          }

          return String(d);
        })
      );
    }
  }, [serverData]);

  const selectOptions = (
    query === "" || serverData
      ? filterOptions
      : filterOptions?.filter((v) => v.toLowerCase().includes(query.toLowerCase()))
  )?.filter((v) => !selected.includes(v));

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!inputRef.current) {
      return;
    }

    inputRef.current.value = "";
    inputRef.current.focus();
  }, [selected]);

  useEffect(() => {
    setValue(selected);
  }, [selected, setValue]);

  return (
    <Combobox
      tw="z-10"
      multiple
      value={selected}
      onChange={(v) => {
        setSelected(v);
        setQuery("");
      }}
    >
      <Box tw="flex items-center flex-wrap gap-0.5 relative w-full text-md bg-white rounded-md border border-gray-300 p-1">
        {selected.map((value) => (
          <Badge
            key={value}
            size="sm"
            color="gray-800"
            weight="medium"
            tw="bg-gray-150 border border-gray-300 cursor-pointer rounded-md"
            wrap
            onClick={() => setSelected((s) => s.filter((e) => e !== value))}
          >
            {value}
            <X size="10" />
          </Badge>
        ))}

        <Combobox.Input
          ref={inputRef}
          tw="flex-grow outline-none"
          onChange={(ev) => setQuery(ev.target.value)}
          onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
            const prevSearch = (event.target as HTMLInputElement).value;

            if (
              event.key === "Backspace" &&
              selected.length !== 0 &&
              prevSearch.length === 0
            ) {
              const targetDelete = selected[selected.length - 1];
              if (targetDelete) {
                setSelected((s) => s.filter((st) => st !== targetDelete));
              }
            }
          }}
        />

        <Absolute align="centerRight">
          <Combobox.Button tw="mr-1">
            <ChevronsUpDown size="16" />
          </Combobox.Button>
        </Absolute>

        {((!selectOptions && query.length) ||
          (selectOptions && selectOptions.length > 0)) && (
          <Combobox.Options tw="flex flex-col bg-white border border-gray-300 absolute top-full left-0 mt-0.5 w-full rounded-md shadow-lg p-0.5 overflow-y-auto max-h-[40vh]">
            {selectOptions ? (
              selectOptions.map((option) => (
                <Combobox.Option
                  key={"option" + option}
                  value={option}
                  tw="px-1 py-0.75 hover:bg-gray-100 ui-active:bg-gray-100 text-left cursor-pointer"
                >
                  {option}
                </Combobox.Option>
              ))
            ) : (
              <Combobox.Option
                key={"option-add"}
                value={query}
                tw="px-1 py-0.75 hover:bg-gray-100 ui-active:bg-gray-100 text-left cursor-pointer"
              >
                Añadir &quot;{query}&quot; a la lista
              </Combobox.Option>
            )}
          </Combobox.Options>
        )}
      </Box>
    </Combobox>
  );
};
