import { useCallback } from "react";

import { isServer } from "@/app/core/utils/helpers";

export const usePageScroll = (withHtml = false) => {
  return useCallback(
    (scroll: boolean) => {
      if (isServer) {
        return;
      }

      const key = scroll ? "remove" : "add";

      window.document.body.classList[key]("no-scroll");
      if (withHtml) {
        window.document.documentElement.classList[key]("overflow-hidden");
      }
    },
    [withHtml]
  );
};
