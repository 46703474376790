import React, { useEffect, useState } from "react";

import { ChevronLeft } from "lucide-react";
import tw from "twin.macro";

import { FilterList } from "@/app/core/datatable/filters/components/FilterList";
import { FilterTab } from "@/app/core/datatable/filters/components/FilterTab";
import { Sidebar } from "@/app/core/display/sidebar/Sidebar";
import { Box } from "@/app/core/ui/components/Box";
import { Flex } from "@/app/core/ui/components/Flex";
import { Text } from "@/app/core/ui/components/Text";
import type { ProvidedState } from "@/app/core/utils/hooks/useProvidedState";
import { useProvidedState } from "@/app/core/utils/hooks/useProvidedState";
import type { ClientFacets, TableFiltersFacet, TableFiltersFacetItem } from "@obd/common";

interface TableFiltersProps extends React.HTMLAttributes<HTMLButtonElement> {
  filters: TableFiltersFacet[];
  activeFilters: ClientFacets;
  setActiveFilters: React.Dispatch<React.SetStateAction<ClientFacets>>;
  simple?: boolean;
  providedIsFiltering?: ProvidedState<boolean>;
}

// eslint-disable-next-line comma-spacing
export const TableFilters: React.FC<TableFiltersProps> = ({
  filters,
  activeFilters,
  setActiveFilters,
  simple = false,
  providedIsFiltering,
  ...props
}) => {
  const [isFiltering, setIsFiltering] = useProvidedState({
    providedState: providedIsFiltering,
    initialState: false,
  });

  const [currentScreen, setCurrentScreen] = useState(0);
  const [filterIndex, setFilterIndex] = useState(0);
  const [currentFilter, setCurrentFilter] = useState<TableFiltersFacetItem>();

  useEffect(() => {
    if (activeFilters.length > 0) {
      setCurrentScreen(1);
    } else {
      setCurrentScreen(0);
    }
  }, [activeFilters.length]);

  return (
    <>
      <Flex
        as="button"
        center
        tw="relative bg-white border border-gray-300 px-1 rounded-lg gap-1 hover:bg-gray-150 transition-colors shadow-sm active:shadow-none"
        onClick={() => setIsFiltering((s) => !s)}
        css={isFiltering ? tw`bg-gray-150 shadow-none` : {}}
        {...props}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <line x1="3" y1="7" x2="21" y2="7"></line>
          <line x1="5.5" y1="12" x2="18.5" y2="12"></line>
          <line x1="8" y1="17" x2="16" y2="17"></line>
        </svg>
        <Text size="md" weight="medium">
          Filtrar
        </Text>

        {activeFilters.length > 0 && (
          <Box tw="absolute -right-0.25 -top-0.25 w-1 h-1 bg-accent rounded-full [box-shadow: 0 4px 6px -1px #6CAEE691]" />
        )}
      </Flex>

      <Sidebar
        open={isFiltering}
        setOpen={setIsFiltering}
        tw="w-[90vw] md:w-[60vw] lg:w-[40vw] overflow-y-auto"
      >
        <Text weight="bold" size="2xl" tw="bg-gray-150 w-full p-3">
          {[0, 1].includes(currentScreen) ? (
            "Todos los filtros"
          ) : (
            <Text
              as="button"
              weight="bold"
              tw="flex items-center gap-0.5 hover:underline"
              onClick={() => {
                setCurrentScreen(0);
                setCurrentFilter(undefined);
              }}
              size="lg"
            >
              <ChevronLeft size="16" /> Volver
            </Text>
          )}
        </Text>

        {currentScreen === 0 && (
          <Flex tw="flex-col px-3 py-3 gap-2">
            {filters?.map((filterGroup, i) => (
              <Flex tw="flex-col" key={filterGroup.title + i}>
                <Text
                  weight="bolder"
                  tw="pb-1 mb-1 px-1 border-b border-gray-200"
                  size="base"
                >
                  {filterGroup.title}
                </Text>

                {filterGroup.items.map((item, idx) => (
                  <Text
                    as="button"
                    tw="text-left py-1 hover:bg-gray-150 px-1 rounded-md"
                    key={item.id + idx}
                    onClick={() => {
                      setCurrentScreen(2);
                      setCurrentFilter(item);
                    }}
                  >
                    {item.name}
                  </Text>
                ))}
              </Flex>
            ))}
          </Flex>
        )}

        {currentScreen === 1 && (
          <FilterList
            filters={filters}
            activeFilters={activeFilters}
            clearFilters={() => setActiveFilters([])}
            simple={simple}
            onFilterAdd={(blockIndex) => {
              if (blockIndex === undefined) {
                if (!activeFilters.some((f) => f.length === 0)) {
                  setActiveFilters((s) => [...s, []]);
                }
              } else {
                setFilterIndex(blockIndex);
                setCurrentScreen(0);
              }
            }}
            onFilterRemove={(blockIndex, itemIndex) => {
              setActiveFilters((s) => {
                let nextActive = s;

                if (itemIndex === undefined) {
                  nextActive = nextActive.filter((_, i) => i !== blockIndex);
                } else {
                  nextActive = nextActive.map((block, i) =>
                    i === blockIndex ? block.filter((_, i2) => i2 !== itemIndex) : block
                  );
                }

                const currentEmpty = nextActive.filter((f) => f.length === 0);
                if (currentEmpty.length > 1) {
                  let nFiltered = currentEmpty.length - 1;
                  nextActive = nextActive.filter((f) => {
                    if (nFiltered === 0) {
                      return true;
                    }

                    if (f.length === 0) {
                      nFiltered--;
                      return false;
                    }

                    return true;
                  });
                }

                if (nextActive.every((f) => f.length === 0)) {
                  setCurrentScreen(0);
                  return [];
                }

                return nextActive;
              });
            }}
          />
        )}

        {currentScreen === 2 && currentFilter !== undefined && (
          <FilterTab
            filter={currentFilter}
            setFilter={(filter) => {
              setCurrentScreen(1);

              if (!activeFilters.length) {
                setActiveFilters([[filter]]);
              } else {
                setActiveFilters((s) => {
                  return s.map((f, i) => {
                    if (i !== filterIndex) {
                      return f;
                    }

                    return [...f, filter];
                  });
                });
              }
            }}
          />
        )}
      </Sidebar>
    </>
  );
};
