import type { Dispatch, SetStateAction } from "react";
import { useState } from "react";

export type ProvidedState<S> = [S, Dispatch<SetStateAction<S>>];

export function useProvidedState<S>(data: {
  initialState?: S | (() => S);
  providedState?: ProvidedState<S>;
}): [S, Dispatch<SetStateAction<S>>];
export function useProvidedState<S>(): [
  S | undefined,
  Dispatch<SetStateAction<S | undefined>>
];
export function useProvidedState<S>(data?: {
  initialState?: S | (() => S);
  providedState?: [S, Dispatch<SetStateAction<S>>];
}):
  | [S, Dispatch<SetStateAction<S>>]
  | [S | undefined, Dispatch<SetStateAction<S | undefined>>] {
  const internalState = useState<any>(data?.initialState);

  if (data?.providedState) {
    return data.providedState;
  }

  return internalState;
}
