import React, { useEffect } from "react";

import { Dialog } from "@headlessui/react";
import { X } from "lucide-react";
import tw from "twin.macro";

import { usePageScroll } from "@/app/core/display/page/hooks/usePageScroll";
import { Absolute } from "@/app/core/ui/components/Absolute";
import { Box } from "@/app/core/ui/components/Box";
import { Button } from "@/app/core/ui/components/Button";
import type { Flex } from "@/app/core/ui/components/Flex";
import { isServer } from "@/app/core/utils/helpers";

interface SidebarProps extends React.ComponentProps<typeof Flex> {
  open: boolean;
  setOpen: (v: boolean) => void;
}

export const Sidebar: React.FC<SidebarProps> = ({
  open,
  children,
  setOpen,
  ...props
}) => {
  const setCanScroll = usePageScroll();

  useEffect(() => {
    setCanScroll(!open);
  }, [open, setCanScroll]);

  if (isServer) {
    return null;
  }

  return (
    <Dialog
      static
      open={open}
      tw="fixed top-0 left-0 w-full h-full flex justify-end z-[50] transition-opacity duration-300"
      css={{
        ...(open ? {} : tw`opacity-0 pointer-events-none`),
      }}
      onClose={() => setOpen(false)}
    >
      <Box tw="fixed inset-0 bg-[rgba(13, 15, 15, 0.24)]" aria-hidden="true" />

      <Dialog.Panel
        tw="bg-white relative h-full min-w-[20%] transition-transform transform"
        css={{ ...(open ? tw`translate-x-0` : tw`translate-x-full`) }}
        {...props}
      >
        <Absolute align="topRight">
          <Button
            theme="light-gray"
            tw="w-4 h-4 flex items-center justify-center mt-1 mr-1"
            onClick={() => setOpen(false)}
          >
            <X size="16" />
          </Button>
        </Absolute>

        {children}
      </Dialog.Panel>
    </Dialog>
  );
};
